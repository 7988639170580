import React from 'react'
import {AiFillFacebook, AiFillYoutube} from 'react-icons/ai';
import {FaBloggerB} from 'react-icons/fa';

const HeaderSocial = () => {
  return (
    <div className="header__socials">
        <a href="#"><AiFillFacebook /></a>
        <a href="#"><AiFillYoutube /></a>
        <a href="#"><FaBloggerB /></a>
    </div>
  )
}

export default HeaderSocial