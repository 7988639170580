import './footer.css'
import {AiFillFacebook, AiFillInstagram, AiFillTwitterCircle} from 'react-icons/ai'


const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>NAVRAS</a>
      <ul className='permalinks'>
       <li><a href="#">Home</a></li>
       <li><a href="#about">About</a></li>
       <li><a href="#experience">Experience</a></li>
       {/* <li><a href="#service">Services</a></li> */}
       <li><a href="#portfolio">Portfolio</a></li>
       {/* <li><a href="#testimonials">Testimonials</a></li> */}
       <li><a href="#contact">Contact</a></li>

      </ul>
      <div className="footer__socials">
        <a href="#"><AiFillFacebook /></a>
        <a href="#"><AiFillInstagram /></a>
        <a href="#"><AiFillTwitterCircle /></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; Navras Shrestha.  All right reserved.</small>
      </div>
    </footer>
  )
}

export default Footer