import './about.css'
import ME from '../../assets/about-me.jpg'
import {MdWork} from 'react-icons/md'
import {CiUser} from 'react-icons/ci'
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Navras Shrestha"  />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <MdWork className="about__icon" />
              <h5>Experience</h5>
              <small>5+ Years Working</small>
            </article>

            <article className="about__card">
              <CiUser className="about__icon" />
              <h5>Clients</h5>
              {/* <small>50+ WorldWide</small> */}
            </article>

            <article className="about__card">
              <AiOutlineFundProjectionScreen className="about__icon" />
              <h5>Projects</h5>
              {/* <small>20+ Completed</small> */}
            </article>
          </div>
          <p>My name is Navras Shrestha, I'm a front-end developer based in Kathmandu, Nepal.I have started my web career from the 2010 as a Web designer and Graphic Designer. I always love to learn something new which can help to update and upgrade my skills. </p>
          <a href="#contact" className="btn btn-primary">Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About