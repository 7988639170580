import './header.css'
import CTA from './CTA'
import HeaderSocial from './HeaderSocial'
import ME from '../../assets/me.jpg'

const Header = () => {
  return (
    <section id="header">
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Navras Shrestha</h1>
        <h5 className="text-light">Front-end Developer</h5>
        <CTA />
        <HeaderSocial />
        <div>
          <img src={ME} alt="me" className="me" />
        </div>
        <a href="#contact" className='scroll__down'>Scroll Down</a>
        
        
       
      </div>

    </section>
  )
}

export default Header