import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.jpg'
import IMG6 from '../../assets/portfolio6.jpg'
import IMG7 from '../../assets/portfolio7.jpg'
import IMG8 from '../../assets/portfolio8.jpg'
import IMG9 from '../../assets/portfolio9.jpg'
import IMG10 from '../../assets/portfolio10.jpg'
const data =[
  {
    id:1,
    image:IMG7,
    title:"King of Purssia.",
    github:'#',
    demo:"https://webdesignd.com/kingofprussia/"
  },
  {
    id:2,
    image:IMG8,
    title:"SPR Builders Inc.",
    github:'#',
    demo:"https://webdesignd.com/stevesremodel"
  },
  {
    id:3,
    image:IMG9,
    title:"Aerial Survey & Photo, INC.",
    github:'#',
    demo:"http://aerialsurveyandphoto.com/"
  },
  {
    id:4,
    image:IMG10,
    title:"North Side Renovations",
    github:'#',
    demo:"https://webdesignd.com/nsrenovation/index.php"
  },
  {
    id:5,
    image:IMG1,
    title:"Affordable Budget Celebrant.",
    github:'#',
    demo:"#"
  },
  {
    id:6,
    image:IMG3,
    title:"Fashion and Photography.",
    github:'#',
    demo:"#"
  },
  {
    id:7,
    image:IMG2,
    title:"Hilands",
    github:'#',
    demo:"#"
  },
  {
    id:8,
    image:IMG4,
    title:"Furniture Removals.",
    github:'#',
    demo:"#"
  },
  {
    id:9,
    image:IMG5,
    title:"The Lakhey, Nepali Metal Band.",
    github:'#',
    demo:"https://www.thelakhey.com.np"
  },
  {
    id:10,
    image:IMG6,
    title:"Angel Cleaning Services.",
    github:'#',
    demo:"#"
  }
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {
          data.map(({id, image, title, demo})=>{
            return(
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} title={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  {/* <a href={github} className="btn">Github</a> */}
                  <a href={demo} target={demo !== '#' ? '_blank' : ''} className="btn btn-primary">Live Demo</a>
                </div>
              </article>
            )
          })
        }   
      </div>
    </section>
  )
}

export default Portfolio