import {useState} from 'react'
import './navbar.css' 
import {AiFillHome, AiOutlineUser, AiFillContacts} from 'react-icons/ai'
import {BsFillBagPlusFill} from 'react-icons/bs'

const Navbar = () => {
  const[activeNav, setActiveNav] = useState("#")
  return (
    <nav id="navbar" className="navbar">
      <a href="#" onClick={()=>setActiveNav("#")} className={activeNav === '#' ? 'active' : ''}><AiFillHome /></a>
      <a href="#about" onClick={()=>setActiveNav("#about")} className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser /></a>
      {/* <a href="#service" onClick={()=>setActiveNav("#service")} className={activeNav === '#service' ? 'active' : ''}><AiFillCustomerService /></a> */}
      <a href="#portfolio" onClick={()=>setActiveNav("#portfolio")} className={activeNav === '#portfolio' ? 'active' : ''} ><BsFillBagPlusFill /></a>
      <a href="#contact" onClick={()=>setActiveNav("#contact")} className={activeNav === '#contact' ? 'active' : ''}><AiFillContacts /></a>
    </nav>
  )
}

export default Navbar