import './contact.css'
import {MdOutlineEmail}from'react-icons/md'
import {RiMessengerLine}from'react-icons/ri'
import {BsWhatsapp}from'react-icons/bs'
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';



const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e6rw2bj', 'template_gdivri4', form.current, 'E9IsvMZwgpHj3WrJD')
    .then((result) => {
      console.log(result.text)
          setMessage("Message Sent Successfully.");
      }, (error) => {
        console.log(error.text)
          setMessage("Something went Wrong!");
      });
      e.target.reset();
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>navrasshrestha@gmail.com</h5>
            <a href="mailto:navrasshrestha@gmail.com" target="_blank">Send an Email</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>@Navras</h5>
            <a href="https://m.me/dimebag.shrestha" target="_blank">Let's talk in Messenger</a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>Whatsapp</h4>
            <h5>@Navras</h5>
            <a href="https://api.whatsapp.com/send?phone=9779823168209" target="_blank">Send a Message</a>
          </article>
        </div>
        
        <form ref={form} onSubmit={sendEmail}>
          {message && message}
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name="email" placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className="btn btn-primary">Send Message</button>
        
        </form>

      </div>
    </section>
  )
}

export default Contact