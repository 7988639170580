import About from './sections/about/About'
import Header from './sections/header/Header'
import Navbar from './sections/navbar/Navbar'
import Portfolio from './sections/portfolio/Portfolio'
import Experience from './sections/experience/Experience'
import Contact from './sections/contact/Contact'
import Footer from './sections/footer/Footer'

const App = () => {
  return (
    <main>
     <Header />
     <Navbar/>
     <About />
     <Experience />
     <Portfolio />
     <Contact />
     <Footer />
    </main>
  )
}

export default App